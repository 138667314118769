import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

export default function ComposableDefi() {
  return (
    <section className="section bg-theme tc-light" id="composable_defi">
      <div className="container">
        <div className="nk-block nk-block-features-s2">
          <div className="row align-items-center gutter-vr-30px">
            <div className="col-md-6">
              <div
                className="gfx animated"
                data-animate="fadeInUp"
                data-delay=".1"
              >
                <StaticImage
                  src="../../static/assets/images/gfx/gfx-u-light.png"
                  alt="Composable DeFi Economics"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="nk-block-text">
                <h6
                  className="title title-xs title-s1 tc-primary animated"
                  data-animate="fadeInUp"
                  data-delay=".2"
                >
                  Next-Gen
                </h6>
                <h2
                  className="title title-semibold animated"
                  data-animate="fadeInUp"
                  data-delay=".3"
                >
                  Composable DeFi Economics
                </h2>
                <p className="animated" data-animate="fadeInUp" data-delay=".4">
                  DeFi Composability enables economic systems to interact with
                  each other and create synergies in order to improve outcome
                  probability. ChangeX aims to offer a mobile-friendly app
                  interface that will tap into this opportunity. Non tech-savvy
                  individuals will be able to take advantage of non-custodial
                  industry-leading products such as cross-chain bridges,
                  staking, lending, DAOs, and collateralized staking in
                  Lego-like combinations that aim to provide lower risk with
                  higher reward. If blockchain's complex and interoperable
                  ecosystem is the backend, then ChangeX is the front-end, the
                  keys to unlock everything, make sense of it, and make it
                  accessible. DeFi, crypto, banking – true composability is only
                  possible when everything is included into one package. At
                  ChangeX, that’s exactly what we want to achieve.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
