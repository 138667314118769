import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

export default function GetInTouch() {
  return (
    <section
      class="section section-contact bg-theme-alt tc-light ov-h"
      id="contact"
    >
      <div class="container">
        <div class="nk-block block-contact">
          <div class="row justify-content-center gutter-vr-30px">
            <div class="col-lg-3">
              <div class="section-head section-head-sm section-head-s9 tc-light text-center text-lg-left">
                <h6
                  class="title title-xs title-s1 tc-primary animated"
                  data-animate="fadeInUp"
                  data-delay=".1"
                >
                  Contact
                </h6>
                <h2
                  class="title animated"
                  data-animate="fadeInUp"
                  data-delay=".2"
                >
                  Get In Touch
                </h2>
                <p class="animated" data-animate="fadeInUp" data-delay=".3">
                  Any question? Reach out to us and we’ll get back to you
                  shortly.
                </p>
              </div>
              <div class="d-flex flex-column justify-content-between h-100">
                <ul class="contact-list contact-list-s2">
                  <li class="animated" data-animate="fadeInUp" data-delay=".5">
                    <em class="contact-icon fas fa-envelope"></em>
                    <div class="contact-text">
                      <span>inquiries@changex.io</span>
                    </div>
                  </li>
                  <li class="animated" data-animate="fadeInUp" data-delay=".6">
                    <em class="contact-icon fas fa-paper-plane"></em>
                    <div class="contact-text">
                      <a href="https://t.me/changex_official">
                        {" "}
                        <span>Join us on Telegram</span>{" "}
                      </a>
                    </div>
                  </li>
                  <li className="animated" data-animate="fadeInUp" data-delay=".5">
                    <div className="contact-text">
                      <em className="contact-icon social-icon fab fa-twitter"></em>
                      <a href="https://twitter.com/ChangeXapp">
                        {" "}
                        <span>Follow us on Twitter</span>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 offset-lg-1">
              <div class="gap-6x d-none d-lg-block"></div>
              <div class="gap-4x d-none d-lg-block"></div>
            </div>
            <div class="col-lg-4 align-self-center">
              <div
                class="nk-block-img animated"
                data-animate="fadeInUp"
                data-delay="1.1"
              >
                <StaticImage
                  src="../../static//assets/images/gfx/gfx-q.png"
                  alt="lungwort"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
