import * as React from "react";
import AdditionalUsp from "../components/AdditionalUsp";
import ComposableDefi from "../components/ComposableDefi";
import DownloadDocuments from "../components/DownloadDocuments";
import FAQ from "../components/FAQ";
import Footer from "../components/Footer";
import GetInTouch from "../components/GetInTouch";
import Header from "../components/Header";
import ICO from "../components/ICO";
import Partners from "../components/Partners";
import Platform from "../components/Platform";
import RoadMap from "../components/Roadmap";
import Team from "../components/Team";
import TheToken from "../components/TheToken";
import USP from "../components/USP";
import Advisors from "../components/Advisors"
import MetaDecorator from "../Utils/MetaDecorator";
import content from "../../static/assets/content/content.json";
const imageUrl = "/assets/images/open-graph/open-graph-image.jpg"

const IndexPage = () => {
  return (
    <>
      <div className="nk-wrap">
          <MetaDecorator
              description={content.pageDescription}
              title={content.pageTitle}
              imageAlt={content.metaImageAlt}
              imageUrl={imageUrl}
          />
        <Header />
        <main className="nk-pages">
          <USP />
          <AdditionalUsp />
          <ComposableDefi />
          <Platform />
          <TheToken />
          <ICO />
          <RoadMap />
          <FAQ />
          <Team />
          <Advisors />
          <DownloadDocuments />
          <Partners />
          <GetInTouch />
          <Footer />
        </main>
      </div>
      <div className="preloader">
        <span className="spinner spinner-round"></span>
      </div>
    </>
  );
};

export default IndexPage;
