import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

export default function Partners() {
  return (
    <section className="section bg-theme-alt tc-light">
      <div className="container">
        <div className="section-head section-head-s9 wide-md">
          <h6
            className="title title-xs title-s1 tc-primary animated"
            data-animate="fadeInUp"
            data-delay=".1"
          >
            Partners
          </h6>
          <h2
            className="title title-semibold animated"
            data-animate="fadeInUp"
            data-delay=".2"
          >
            Supported By
          </h2>
        </div>

        <div className="nk-block block-partners mgb-m30">
          <ul className="partner-list partner-list-left partner-list-s3 flex-wrap d-flex  align-items-center">
            <li
              className="partner-logo partner-logo-s2 animated mr-md-5"
              data-animate="fadeInUp"
              data-delay=".3"
            >
              <img
                style={{ height: 90 }}
                width={272.98}
                height={90}
                src="assets/images/partners/hydra.svg"
                alt="partner"
              />
            </li>
            <li
              className="partner-logo partner-logo-s2 animated"
              data-animate="fadeInUp"
              data-delay=".5"
            >
              <img
                style={{ height: 80 }}
                width={232.18}
                height={80}
                src="assets/images/partners/locktrip.svg"
                alt="partner"
              />
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
