import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import TypeIt from "typeit-react";
import initCountdown from "../../static/assets/js/countdown";
import Navigation from "./Nav";

export default function Header() {
  const getTypingInstance = (instance) => {
    let strings = [
      "Leveraged Staking For <span className='tc-primary'>2x APR</span>",
      "<span className='tc-primary'>Visa Card</span> Connected To POS Assets",
      "One-Click <span className='tc-primary'>Crypto-to-Fiat</span> Trading",
      "<span className='tc-primary'>Personal IBAN</span> via SEPA",
      "Gorgeous <span className='tc-primary'>Mobile</span> Experience",
    ];

    strings.forEach((str) => {
      instance.type(str).pause(3000).delete().pause(500);
    });

    return instance;
  };

  React.useEffect(() => {
    initCountdown();
  }, []);

  return (
    <header
      className="nk-header page-header is-transparent is-sticky is-shrink is-dark"
      id="header"
    >
      <Navigation />

      <div className="header-banner bg-theme-grad-s2 header-banner-lungwort ov-h tc-light">
        <div className="background-shape bs-right"></div>
        <div className="nk-banner">
          <div className="banner banner-fs banner-s2">
            <div className="banner-wrap">
              <div className="container">
                <div className="row align-items-center justify-content-center">
                  <div className="col-xl-6 col-lg-4 order-lg-last p-md-5">
                    <div
                      className="banner-gfx banner-gfx-re-s3 animated"
                      data-animate="fadeInUp"
                      data-delay="1.25"
                    >
                      <StaticImage
                        src="../../static/assets/images/dark/gfx-d-dark.png"
                        alt="header"
                      />
                      <img
                        className="banner-gfx-icon banner-gfx-icon-1"
                        src="/assets/images/icons/icon-x.png"
                        alt=""
                      />
                      <img
                        className="banner-gfx-icon banner-gfx-icon-2"
                        src="/assets/images/icons/icon-x.png"
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-8 text-center text-lg-left">
                    <div className="banner-caption cpn tc-light">
                      <div className="cpn-head">
                        <h1
                          className="title title-xl-2 fw-6 animated"
                          data-animate="fadeInUp"
                          data-delay="1.35"
                        >
                          Boost Your Crypto With <br />
                          <span className="d-block" style={{ height: 100 }}>
                            <TypeIt
                              getBeforeInit={getTypingInstance}
                              options={{ loop: true, speed: 50 }}
                            />
                          </span>
                        </h1>
                      </div>
                      <div className="cpn-text">
                        <h5
                          className="lead animated text-white font-bold"
                          data-animate="fadeInUp"
                          data-delay="1.45"
                        >
                          Next-Gen composable DeFi
                        </h5>
                        <p
                          className="lead animated text-white font-bold"
                          data-animate="fadeInUp"
                          data-delay="1.55"
                        >
                          Introducing the ChangeX app – a hybrid ecosystem for
                          all your Crypto, DeFi, and Banking needs. Combining
                          compliant CeFi with true DeFi elements, ChangeX
                          streamlines the crypto experience for everyone.
                          Brought to you by industry veterans.
                        </p>
                      </div>

                      <div className="cpn-action">
                        <ul
                          className="cpn-links animated"
                          data-animate="fadeInUp"
                          data-delay="1.55"
                        >
                          <li>
                            <a
                              className="link link-primary"
                              href="https://firebasestorage.googleapis.com/v0/b/changex-io.appspot.com/o/changex-whitepaper-v4.pdf?alt=media&token=85d7033d-ce62-4fd2-aefd-b50f2a758530"
                            >
                              <em className="link-icon icon-circle ti ti-files"></em>{" "}
                              <span>White Paper</span>
                            </a>
                          </li>
                          <li>
                            <a
                              className="link link-primary"
                              href="https://t.me/changex_official"
                            >
                              <em className="link-icon icon-circle ti ti-file"></em>{" "}
                              <span>Community</span>
                            </a>
                          </li>
                          <li>
                            <a
                              className="link link-primary"
                              href="https://medium.com/locktrip/unveiling-project-x-leveraged-staking-defi-fiat-trading-visa-card-for-hydra-ecosystem-b9bfdf872de5"
                            >
                              <em className="link-icon icon-circle ti ti-file"></em>{" "}
                              <span>Announcement</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div
                        className="token-status token-status-s2 animated"
                        data-animate="fadeInUp"
                        data-delay="1.65"
                      >
                        <div className="token-box token-box-s2 round">
                          <h5 className="title title-xs-alt fw-6">
                            $CHANGE is traded on
                          </h5>
                          <div
                            className="countdown-s3 countdown-s4 countdown"
                            data-date="2022/07/19 17:00:00"
                          ></div>
                        </div>
                        <div className="token-action token-action-s1">
                          <div className="dex-btn">
                            <a
                                className="btn btn-md btn-grad dex"
                                href="https://hydradex.org/#/swap"
                            >
                              HydraDEX
                            </a>
                          </div>
                          <div className="dex-btn"> <a
                              className="btn btn-md btn-grad"
                              href="https://v2.info.uniswap.org/pair/0x9247f812ff1037e15be98f12f76f4b011abf4d8b"
                          >
                            Uniswap
                          </a></div>
                        </div>
                        <div className="separator">OR DOWNLOAD FROM</div>
                        <div className="token-action token-action-s3">
                          <div className="token-action token-action-s1">

                            <a
                                className=""
                                href="https://play.google.com/store/apps/details?id=io.changex.app"
                            >
                              <img alt="" src="/assets/images/google-play.png"/>
                            </a>
                          </div>
                          <div className="token-action token-action-s1">
                            <a
                                className=""
                                href="https://apps.apple.com/bg/app/changex-wallet/id1613309180"
                            >
                              <img alt="" src="/assets/images/apps-store.png"/>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="particles-bg"
          className="particles-container particles-bg"
          data-pt-base="#00c0fa"
          data-pt-base-op=".3"
          data-pt-line="#2b56f5"
          data-pt-line-op=".5"
          data-pt-shape="#00c0fa"
          data-pt-shape-op=".2"
        ></div>
      </div>
    </header>
  );
}
