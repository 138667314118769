import * as React from "react";
import initChart from "../../static/assets/js/charts";

export default function ICO() {
  const icoFeatures = [
    { title: "Public hard cap", content: "$2.4M", color: 1 },
    {
      title: "Tokens for public sale",
      content: "150,000,000 CHANGE",
      color: 2,
    },
    { title: "Price for public sale", content: "$0.016", color: 3 },
    { title: "Cap per user/enrollment", content: "$100,000", color: 4 },
    {
      title: "Payment methods",
      content: "USDT, USDC, UST, BUSD, BTC, ETH, Hydra and more",
      color: 5,
    },
    {
      title: "Deposit for enrollment",
      content: "3% of subscribed amount",
      color: 6,
    },
    { title: "Pre-sale", content: "None", color: 1 },
    { title: "Enrollment starts at", content: "28 Feb 2022", color: 2 },
    { title: "Enrollment closes at", content: "31 May 2022", color: 3 },
    { title: "Subscription calculation*", content: "1 June 2022", color: 4 },
    { title: "Funds commitment deadline", content: "30 June 2022", color: 5 },
    {
      title: "Tentative IDO date",
      content: "First Week of July 2022",
      color: 1,
    },
  ];

  const tokenDistribution = [
    { title: "Public Sale ($2.4M)", value: 150000000 },
    { title: "Founders & Team ", value: 22500000 },
    { title: "Treasury", value: 97500000 },
    { title: "Liquidity", value: 30000000 },
    { title: "Airdrop", value: 5000000 },
    { title: "Tier 1 Institutionals", value: 50000000 },
    { title: "Staking Pool for first 9 months", value: 20000000 },
    { title: "Launchpads & IEO", value: 50000000 },
  ];

  const formatNumber = (num = 0) => {
    const numarray = num.toString().split(".");
    return numarray[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const totalTokenDistribution = tokenDistribution.reduce(
    (memo, num) => memo + num.value,
    0
  );

  const tokenDistributionData = tokenDistribution
    .sort((a, b) => b.value - a.value)
    .map((item) => ({
      ...item,
      percentage: ((item.value / totalTokenDistribution) * 100).toFixed(1),
      value: formatNumber(item.value) + " CHANGE",
    }));

  React.useEffect(() => {
    initChart();
  }, []);

  return (
    <>
      <section className="section" id="ico">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-6">
              <div class="section-head section-head-s4 wide-auto-sm text-center tc-light">
                <h2
                  class="title title-s2 title-s2-alt animated"
                  data-animate="fadeInUp"
                  data-delay=".1"
                >
                  Token Sale
                </h2>

                <p
                  className="animated"
                  data-animate="fadeInUp"
                  data-delay="0.2"
                >
                  $CHANGE ICO’s tokensale phase closed on June 1 with 1.8x oversubscription.
                  We are currently in the commitment phase, where users have until July 1 to pay for the rest of the CHANGE
                  they subscribed for. Below is a breakdown of the token allocation.

                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container container-xxl tc-light">
          <div className="nk-block">
            <div className="row align-items-center gutter-vr-50px">
              <div className="col-xxl-4 col-lg-6 px-xl-5 order-xl-1">
                <div
                  className="animated"
                  data-animate="fadeInUp"
                  data-delay="0.3"
                >
                  <canvas
                    className="chart-canvas"
                    id="token-distribution"
                  ></canvas>
                </div>
              </div>
              <div className="col-xxl col-lg-6">
                <ul
                  className="chart-data-s2 row "
                  data-canvas="token-distribution"
                  data-canvas-type="pie"
                  data-border-color="#0f1932"
                >
                  {tokenDistributionData.map(({ title, value, percentage }) => (
                    <li
                      className="col-sm-6"
                      data-color="#1f2e4d"
                      data-color-hover="#46bdf4"
                      data-label={title}
                      data-title={title}
                      data-subtitle={value}
                      data-amount={percentage}
                    >
                      {title}
                    </li>
                  ))}
                </ul>
                <small className="d-sm-block mt-5">
                  There will be an invite-only private placement with Tier 1
                  institutional investors, who could contribute to the project’s
                  adoption.
                </small>
              </div>
              <div className="col-xxl col-xl-12 order-last">
                <div className="token-info-s2 tc-light">
                  <div className="row gutter-vr-50px">
                    {icoFeatures.map(({ title, content, color }, index) => (
                      <div className="col-6 col-xxl-6 col-lg-4 ">
                        <div
                          className={`token-info-item color-${color} animated`}
                          data-animate="fadeInUp"
                          data-delay={index / 10 + 0.5}
                        >
                          <div className="token-info-title">{title}</div>
                          <h4 className="token-info-des">{content}</h4>
                        </div>
                      </div>
                    ))}
                  </div>
                  <small className="d-sm-block mt-5">
                    *The oversubscription level for CHANGE is 1.8x,
                    which means that a quota reduction mechanism comes into effect,
                    reducing the amount of tokens everyone receives. This provides a
                    fair allocation for CHANGE and a more stable aftermarket foundation
                    for the project by effectively dispersing the tokens to a larger investor base,
                    among other benefits, such as reduced circulating supply.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
