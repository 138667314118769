import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

export default function USP() {
  return (
    <section class="bg-theme-alt tc-light" id="usp">
      <div class="nk-block nk-block-features-s2 pb-5">
        <div class="container">
          <div class="row align-items-center flex-row-reverse gutter-vr-30px">
            <div class="col-md-6">
              <div
                class="gfx-round-bg after animated position-relative text-center"
                data-animate="fadeInUp"
                data-delay=".1"
              >
                <StaticImage
                  className="img-fluid"
                  src="../../static/assets/images/app-screens/leveraged-staking.png"
                  alt="Leverage Staking Product"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="nk-block-text">
                <h6
                  class="title title-xs title-s1 tc-primary animated"
                  data-animate="fadeInUp"
                  data-delay=".2"
                >
                  Earn
                </h6>
                <h2
                  class="title title-semibold animated"
                  data-animate="fadeInUp"
                  data-delay=".3"
                >
                  Leveraged Staking Product
                </h2>
                <p class="animated" data-animate="fadeInUp" data-delay=".4">
                  ChangeX’s Leveraged Staking is among the world’s first, and is
                  specifically designed for PoS and inflationary assets in order
                  to boost APR. By harnessing the power of collateralized
                  staking pools, Leveraged Staking maximizes APR by a factor of
                  1.2-2x, while also powering a stablecoin lending market.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="nk-block nk-block-features-s2 pb-5 bg-theme-grad-s2">
        <div class="container">
          <div class="row align-items-center gutter-vr-30px">
            <div class="col-md-6">
              <div
                class="gfx-round-bg after animated position-relative"
                data-animate="fadeInUp"
                data-delay=".1"
              >
                <StaticImage
                  style={{ position: "relative", zIndex: 2 }}
                  src="../../static/assets/images/app-screens/changex-card.png"
                  alt="Universal Crypto Visa Card"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="nk-block-text">
                <h6
                  class="title title-xs title-s1 tc-primary animated"
                  data-animate="fadeInUp"
                  data-delay=".2"
                >
                  Use
                </h6>
                <h2
                  class="title title-semibold animated"
                  data-animate="fadeInUp"
                  data-delay=".3"
                >
                  Universal Crypto Visa Card
                </h2>
                <p class="animated" data-animate="fadeInUp" data-delay=".4">
                  The ChangeX Visa will allow instant access to HYDRA and other
                  assets without interrupting staking on your interest-bearing
                  assets. Access your funds, while they work for you in the DeFi
                  ecosystem.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="nk-block nk-block-features-s2 pb-5">
        <div class="container">
          <div class="row align-items-center flex-row-reverse gutter-vr-30px">
            <div class="col-md-6">
              <div
                class="gfx-round-bg after animated position-relative"
                data-animate="fadeInUp"
                data-delay=".1"
              >
                <StaticImage
                  className="img-fluid"
                  src="../../static/assets/images/app-screens/fiat-to-crypto-trading.png"
                  alt="Fiat-to-Crypto trading"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="nk-block-text">
                <h6
                  class="title title-xs title-s1 tc-primary animated"
                  data-animate="fadeInUp"
                  data-delay=".2"
                >
                  Trade
                </h6>
                <h2
                  class="title title-semibold animated"
                  data-animate="fadeInUp"
                  data-delay=".3"
                >
                  Fiat-to-Crypto and back
                </h2>
                <p class="animated" data-animate="fadeInUp" data-delay=".4">
                  ChangeX’s cross-chain bridges will bring a plethora of
                  cryptocurrencies, otherwise widely unavailable, at your
                  fingertips. By combining the existing liquidity in the DeFi
                  and CeFi worlds, this simple experience would allow even the
                  most inexperienced users to enter the world of crypto.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="nk-block nk-block-features-s2 pb-5 bg-theme-grad-s2">
        <div class="container">
          <div class="row align-items-center gutter-vr-30px">
            <div class="col-md-6">
              <div
                class="gfx-round-bg after animated position-relative text-center"
                data-animate="fadeInUp"
                data-delay=".1"
              >
                <StaticImage
                  className="img-fluid"
                  src="../../static/assets/images/app-screens/personal-ibans.png"
                  alt="Personal IBANS for SEPA Banking"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="nk-block-text">
                <h6
                  class="title title-xs title-s1 tc-primary animated"
                  data-animate="fadeInUp"
                  data-delay=".2"
                >
                  Control
                </h6>
                <h2
                  class="title title-semibold animated"
                  data-animate="fadeInUp"
                  data-delay=".3"
                >
                  Personal IBANS for SEPA Banking
                </h2>
                <p class="animated" data-animate="fadeInUp" data-delay=".4">
                  By obtaining an Electronic Money Institution (EMI) license for
                  EUR banking capability, ChangeX will allow you to control all
                  of your assets – both fiat and crypto – in a single interface.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
