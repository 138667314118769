import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

export default function Team() {
  const teamMembers = [
    {
      name: "Nick Iliev",
      title: "CEO and Co-founder",
      description:
        "Nick breaths blockchain ever since 2014.Starting with mining he then created the go-to crypto platform in Bulgaria, xChange.bg in 2016. Nick also co-founded RocaPay, a crypto merchant payment gateway. He has a unique background in both IT and Finance with an MSc in Finance and MCSE/CCNP licenses",
      linkedIn: "https://www.linkedin.com/in/nick-iliev-20229b166/",
      image: (
        <StaticImage
          src={"../../static/assets/images/team/nick.jpeg"}
          alt={"Nick Iliev"}
          className="round-full"
        />
      ),
    },
    {
      name: "Gary Guerassimov",
      title: "Co-founder",
      description:
        "Gary is a serial entrepreneur with 15 years of experience in management and business development. Co-founder of xChange.bg and Finexify, a DeFi investment fund with $6+ M AUM. He holds a BA in International Business Management from DeVos Graduate School. ",
      linkedIn: "https://www.linkedin.com/in/gary-guerassimov-42620536/",
      image: (
        <StaticImage
          src={"../../static/assets/images/team/gary.jpg"}
          alt={"Gary Guerassimov"}
          className="round-full"
        />
      ),
    },
    {
      name: "Petya Nankova",
      title: "COO",
      description:
        "Finance professional with vast experience in VC, M&A and FX. She oversaw, managed and was instrumental in the growth of xChange.bg, the leading crypto platform in Bulgaria. She holds an MSc in Finance & Investments from Erasmus University Rotterdam.",
      linkedIn: "https://www.linkedin.com/in/petya-nankova-62961284/",
      image: (
        <StaticImage
          src={"../../static/assets/images/team/petya.jpeg"}
          alt={"Petya Nankova"}
          className="round-full"
        />
      ),
    },
    {
      name: "Martin Kuvandzhiev",
      title: "CTO",
      description:
        "Martin is a core developer at Bitcoin Gold, a blockchain speaker/advocate and an entrepreneur with vast experience in Startups and Fintech. Martin has also built Assetify, а service provider for crypto-backed loans and is leading 20+ developers focused on blockchain technologies.",
      linkedIn: "https://www.linkedin.com/in/martin-kuvandzhiev-061615a2/",
      image: (
        <StaticImage
          src={"../../static/assets/images/team/martin.jpg"}
          alt={"Martin Kuvandzhiev"}
          className="round-full"
        />
      ),
    },
    {
      name: "Nikola Alexandrov",
      title: "Strategy & Token Architecture",
      description:
        "Nikola is a co-founder and ideator of HydraChain (HYDRA) and CEO of LockTrip.com (LOC). He has seven years of experience with liquidity and HFT systems. He co-founded the Bitcoin7 exchange, top 3 by volume back in 2011. Nikola holds a BA in Business Administration from Portsmouth University.",
      linkedIn: "https://www.linkedin.com/in/nikola-alexandrov-hydra/",
      image: (
        <StaticImage
          src={"../../static/assets/images/team/nikola.jpg"}
          alt={"Nikola Alexandrov"}
          className="round-full"
        />
      ),
    },
    {
      name: "Hristo Tenchev",
      title: "Crowdfunding Operations",
      description:
        "Co-founder of LockTrip and Bitcoin7. Founder of xs-software.com, a gaming company with more than 50M registered players. Cofounder at Softuni.bg - an innovative IT education center with more than 300,000 students. He was listed in the European Forbes “30 under 30” as one of the most successful entrepreneurs in 2016.",
      linkedIn: "https://www.linkedin.com/in/hristotenchev/",
      image: (
        <StaticImage
          src={"../../static/assets/images/team/hristo.jpg"}
          alt={"Hristo Tenchev"}
          className="round-full"
        />
      ),
    },
    {
      name: "Petko Krastev",
      title: "Risk & Compliance",
      description:
        "Audit and compliance professional with experience in managing compliance risk in the private banking, wealth management, and cryptocurrency sectors. Petko holds an MSc in International Business Economics from KU Leuven",
      linkedIn: "https://www.linkedin.com/in/petkokrastev/",
      image: (
        <StaticImage
          src={"../../static/assets/images/team/petko.jpg"}
          alt={"Petko Krastev"}
          className="round-full"
        />
      ),
    },
    {
      name: "Florian Pfeiffer",
      title: "Strategy & Design",
      description:
        "CCO and Partner at LockTrip and advisor to GoMeat. His engineering background helps him design tools for automating operations. His passion for data, finance, and innovation led him into the world of crypto.",
      linkedIn: "https://www.linkedin.com/in/florian-pfeiffer-a95a21161/",
      image: (
        <StaticImage
          src={"../../static/assets/images/team/florian.jpeg"}
          alt={"Florian Pfeiffer"}
          className="round-full"
        />
      ),
    },
  ];

  return (
    <section className="section bg-theme-dark-alt tc-light" id="team">
      <div className="background-shape bs-right"></div>
      <div className="container">
        <div className="section-head section-head-s9 wide-md">
          <h6
            className="title title-xs title-s1 tc-primary animated"
            data-animate="fadeInUp"
            data-delay=".1"
          >
            MEET THE TEAM
          </h6>
          <h2
            className="title title-semibold animated"
            data-animate="fadeInUp"
            data-delay=".2"
          >
            Executive team
          </h2>
          <div className="wide-sm">
            <p className="animated" data-animate="fadeInUp" data-delay=".3">
              The ICO Crypto Team combines a passion for esports, industry
              experise &amp; proven record in finance, development, marketing
              &amp; licensing.
            </p>
          </div>
        </div>

        <div className="nk-block nk-block-left nk-block-team-list team-list">
          <div className="row justify-content-start gutter-vr-30px ">
            {teamMembers.map(
              ({ name, title, description, image, linkedIn }, index) => (
                <div className="col-lg-4 col-sm-6 gap-5">
                  <div
                    className="team team-s4 round bg-theme-alt h-100 ml-0 animated"
                    data-animate="fadeInUp"
                    data-delay={index / 10 + 0.2}
                  >
                    <div className="team-photo team-photo-s1 round-full">
                      {image}
                    </div>
                    <h5 className="team-name">{name}</h5>
                    <span className="team-position tc-primary">{title}</span>
                    <div className="team-desc">
                      <p>{description}</p>
                    </div>
                    <ul className="team-social team-social-s2">
                      <li>
                        <a href={linkedIn}>
                          <em className="fab fa-linkedin-in"></em>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
