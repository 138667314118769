import $ from "jquery";
import "jquery-countdown";

export default function initCountdown() {
  var $count = $(".countdown");
  if ($count.length) {
    $count.each(function () {
      var $self = $(this),
        datetime = $self.attr("data-date");

      var _day_text = $self.data("day-text") ? $self.data("day-text") : "Days";
      var _hour_text = $self.data("hour-text")
        ? $self.data("hour-text")
        : "Hours";
      var _min_text = $self.data("min-text") ? $self.data("min-text") : "Min";
      var _sec_text = $self.data("sec-text") ? $self.data("sec-text") : "Sec";

      console.log("$self: ", !!$.countdown);

      $self.countdown(datetime).on("update.countdown", function (event) {
        $(this).html(
          event.strftime(
            '<div class="countdown-item"><span class="countdown-time countdown-time-first">%D</span><span class="countdown-text">' +
              _day_text +
              "</span></div>" +
              '<div class="countdown-item"><span class="countdown-time">%H</span><span class="countdown-text">' +
              _hour_text +
              "</span></div>" +
              '<div class="countdown-item"><span class="countdown-time">%M</span><span class="countdown-text">' +
              _min_text +
              "</span></div>" +
              '<div class="countdown-item"><span class="countdown-time countdown-time-last">%S</span><span class="countdown-text">' +
              _sec_text +
              "</span></div>"
          )
        );
      });
    });
  }
}
