import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

export default function Platform() {
  const features = [
    {
      title: "Non-custodial wallet",
      content:
        "You are in complete control of your assets. Your keys, your coins.",
    },
    {
      title: "Effortless DeFi swaps",
      content:
        "Forget about switching between DEXs – swap tokens in-app at the best rates.",
    },
    {
      title: "Easy lending and staking",
      content: "Earn high APR on PoS Assets and track your earnings.",
    },
    {
      title: "Leveraged staking",
      content:
        "Among the first leveraged staking products without ever leaving the app – up to 2x on base APR.",
    },
    {
      title: "NFT wallet",
      content:
        "Store all your ERC 721 and ERC 1155 standard NFTs inside the ChangeX app.",
    },
    {
      title: "Universal Crypto Debit Card",
      content:
        "Crypto in real life – use the ChangeX debit card to pay with your digital assets.",
    },
    {
      title: "Fiat on- and off-ramp",
      content:
        "Multiple payment methods supported in the app for the easiest access to your crypto.",
    },
    {
      title: "Personal bank account",
      content:
        "No more need to leave the app – your bank account is here and it blends right in.",
    },
  ];

  return (
    <section class="section bg-theme-dark-alt tc-light" id="platform">
      <div class="container">
        <div class="section-head section-head-s9 wide-md">
          <h6
            class="title title-xs title-s1 tc-primary animated"
            data-animate="fadeInUp"
            data-delay=".1"
          >
            The Platform
          </h6>
          <h2
            class="title title-semibold animated"
            data-animate="fadeInUp"
            data-delay=".2"
          >
            In-brief
          </h2>
          <div class="wide-sm">
            <p className="animated" data-animate="fadeInUp" data-delay=".3">
              The ChangeX platform strives to streamline the entire crypto
              experience by merging CEX and DEX tools with in-app functionality
              like staking and lending, while allowing you to spend your digital
              assets with a ChangeX Crypto Debit Card..
            </p>
            <p className="animated" data-animate="fadeInUp" data-delay=".4">
            Trade, store, and earn against your crypto – ChangeX has you covered
            </p>
          </div>
        </div>

        <div class="nk-block nk-block-text-wrap">
          <div class="row align-items-center gutter-vr-30px">
            <div class="col-lg-6">
              <div
                class="nk-block-img animated"
                data-animate="fadeInUp"
                data-delay=".4"
              >
                <StaticImage
                  src="../../static/assets/images/dark/gfx-z-c.png"
                  alt="app"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="nk-block-text">
                <ul class="list list-check list-check-s3">
                  {features.map(({ title, content }) => (
                    <li
                      class="animated"
                      data-animate="fadeInUp"
                      data-delay=".5"
                    >
                      {content}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
