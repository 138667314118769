import $ from "jquery";
import Chart from "chart.js/auto";

export default function initChart() {
  let $chart_s2 = $(".chart-data-s2");

  var $win = $(window);

  // ChartsJS @v1.0

  // @since v1.6
  const Pie = function (
    _canvas,
    _label,
    _title,
    _colors,
    _colors_hover,
    _amounts,
    _canvas_border,
    _canvas_cutout
  ) {
    if ($("#" + _canvas).length) {
      var _canvas_el = document.getElementById(_canvas).getContext("2d");

      var data_set = {
        labels: _label,
        titles: _title,
        datasets: [
          {
            label: "949",
            lineTension: 0,
            backgroundColor: _colors,
            hoverBackgroundColor: _colors_hover,
            borderColor: _canvas_border,
            borderWidth: 2,
            hoverBorderColor: _canvas_border,
            data: _amounts,
            animationDuration: 400,
          },
        ],
      };
      var options_set = {
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              title: function (tooltipItem) {
                return [tooltipItem[0]["label"]];
              },
              label: function (tooltipItem) {
                return tooltipItem.raw + "%";
              },
            },
            backgroundColor: "transparent",
            titleFontSize: 11,
            bodyFontColor: "#fff",
            bodyFontSize: 14,
            bodyFontStyle: "bold",
            bodySpacing: 0,
            yPadding: 0,
            xPadding: 0,
            yAlign: "center",
            xAlign: "center",
            footerMarginTop: 5,
            displayColors: false,
          },
        },
        cutoutPercentage: 0,
        responsive: true,
        maintainAspectRatio: false,

        onHover: function (e, i) {
          if (i.length) {
            var _cur_idx = i[0].index + 1;

            if (_cur_idx > -1) {
              $('[data-canvas="' + _canvas + '"] li').removeClass("active");
              $(
                '[data-canvas="' + _canvas + '"] li:nth-child(' + _cur_idx + ")"
              ).addClass("active");
            }
          } else {
            $('[data-canvas="' + _canvas + '"] li').removeClass("active");
          }
        },
      };

      var pie_chart = new Chart(_canvas_el, {
        type: "pie",
        data: data_set,
        options: options_set,
      });

      // Resize Chart
      $win.on("resize", function () {
        pie_chart.resize();
      });
    }
  };

  // @since v1.6
  if ($chart_s2.length > 0) {
    $chart_s2.each(function () {
      var $chart_data = $(this).children("li"),
        _canvas = $(this).data("canvas"),
        _canvas_border = $(this).data("border-color")
          ? $(this).data("border-color")
          : "#fff",
        _canvas_type = $(this).data("canvas-type");
      _canvas_type =
        typeof _canvas_type === "undefined" || _canvas_type === ""
          ? "doughnut"
          : _canvas_type;
      if (typeof _canvas !== "undefined" && _canvas !== "") {
        var item_label = [],
          item_title = [],
          item_color = [],
          item_color_hover = [],
          item_percent = [];
        $chart_data.each(function () {
          var l = $(this).data("label"),
            t = $(this).data("title"),
            sl = $(this).data("subtitle"),
            c = $(this).data("color"),
            hc = $(this).data("color-hover"),
            p = $(this).data("amount");
          item_label.push(l);
          item_title.push(t);
          item_color.push(c);
          item_color_hover.push(hc);
          item_percent.push(p);
          $(this).html(
            '<div class="chart-data-item"><span class="chart-label">' +
              t +
              '</span><span class="chart-info"><span class="chart-percent">' +
              p +
              '% </span><span class="chart-sublabel">' +
              sl +
              "</span></span></div>"
          );
        });
        if (_canvas_type === "pie") {
          Pie(
            _canvas,
            item_label,
            item_title,
            item_color,
            item_color_hover,
            item_percent,
            _canvas_border
          );
        }
      } else {
      }
    });
  }
}
