import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

export default function FAQ() {
  return (
    <section class="section bg-theme tc-light" id="faqs">
      <div class="container">
        <div class="section-head section-head-s9 wide-md">
          <h6
            class="title title-xs title-s1 tc-primary animated"
            data-animate="fadeInUp"
            data-delay=".1"
          >
            FAQ
          </h6>
          <h2
            class="title title-semibold animated"
            data-animate="fadeInUp"
            data-delay=".2"
          >
            Frequently asked questions
          </h2>
          <div class="wide-sm">
            <p class="animated" data-animate="fadeInUp" data-delay=".3">
              Please find frequently asked questions pertaining to the tokensale
              event and their answers below.
            </p>
          </div>
        </div>

        <div class="nk-block">
          <div class="row justify-content-center align-items-center">
            <div class="col-md-12">
              <ul
                class="nav tab-nav tab-nav-btn pdb-r justify-content-start animated"
                data-animate="fadeInUp"
                data-delay=".4"
              >
                <li>
                  <a
                    class="active"
                    data-toggle="tab"
                    href="#general-questions-13"
                  >
                    Token Sale Event
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#ico-questions-13">
                    Token Economy
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#tokens-sales-13">
                    Product
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-lg-8">
              <div
                class="tab-content animated"
                data-animate="fadeInUp"
                data-delay=".5"
              >
                <div
                  class="tab-pane fade show active"
                  id="general-questions-13"
                >
                  <div class="accordion accordion-faq" id="faq-47">
                    <div class="accordion-item accordion-item-s2 bg-theme-alt">
                      <h5
                        class="accordion-title accordion-title-sm"
                        data-toggle="collapse"
                        data-target="#faq-47-1"
                      >
                        {" "}
                        What does Enrollment and Subscription mean?{" "}
                        <span class="accordion-icon accordion-icon-s2"></span>
                      </h5>
                      <div
                        id="faq-47-1"
                        class="collapse show"
                        data-parent="#faq-47"
                      >
                        <div class="accordion-content">
                          <p>
                            The tokensale will be executed with an optimum
                            risk/reward setup and an extremely limited market
                            cap equivalent to just $2.4M of tokens. The first
                            Phase is the Enrollment, where each user will
                            Subscribe the amount that he/she would like to buy.
                            During the Enrollment phase, users will deposit 3%
                            of the final amount.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item accordion-item-s2 bg-theme-alt">
                      <h5
                        class="accordion-title accordion-title-sm collapsed"
                        data-toggle="collapse"
                        data-target="#faq-47-2"
                      >
                        {" "}
                        What would happen if there is an oversubscription?{" "}
                        <span class="accordion-icon accordion-icon-s2"></span>
                      </h5>
                      <div id="faq-47-2" class="collapse" data-parent="#faq-47">
                        <div class="accordion-content">
                          <p>
                            In case of an oversubscription, the quota will be
                            adjusted proportionately to all participants. For
                            example, if someone has enrolled for $10,000 of
                            CHANGE tokens, and there is a total of 2x
                            oversubscription on the market cap ($4.8M instead of
                            $2.4M), this would mean that the quota will be
                            reduced by 50% for all participants. The goal is to
                            allow a fair distribution where everyone gets equal
                            treatment regardless of subscription size.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item accordion-item-s2 bg-theme-alt">
                      <h5
                          className="accordion-title accordion-title-sm collapsed"
                          data-toggle="collapse"
                          data-target="#faq-47-3"
                      >
                        {" "}
                        Can I stake CHANGE if I used self-vesting?{" "}
                        <span className="accordion-icon accordion-icon-s2"></span>
                      </h5>
                      <div id="faq-47-3" className="collapse" data-parent="#faq-47">
                        <div className="accordion-content">
                          <p>
                            There is a trade-off between buying at the ICO and buying from the HYDRA DEX. Investors who bought at the ICO make sure they bought at $0.016 and also that they can use self-vesting, but being unable to stake until the end of the lock-up period. DEX buyers will buy at an uncertain price, but will have the ability to stake immediately once staking is online.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item accordion-item-s2 bg-theme-alt">
                      <h5
                          className="accordion-title accordion-title-sm collapsed"
                          data-toggle="collapse"
                          data-target="#faq-47-4"
                      >
                        {" "}
                        What is the benefit of buying at the HydraDEX launch??{" "}
                        <span className="accordion-icon accordion-icon-s2"></span>
                      </h5>
                      <div id="faq-47-4" className="collapse" data-parent="#faq-47">
                        <div className="accordion-content">
                          <p>
                            The CHANGE token will be traded against HYDRA and/or DAI with a total supply of 30 M tokens and corresponding 480,000 USD in HYDRA and/or DAI. Meaning that the starting price will be the same as in the ICO - $0.016 per token. However, demand should move the price up and the exact price at which you will be able to buy is not guaranteed. Buying at the DEX, however, gives you the opportunity to immediately stake CHANGE, and with only 67 million tokens in circulation for the 11 months following September, which is the result of all the vested CHANGE, the APR should be quite significant.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item accordion-item-s2 bg-theme-alt">
                      <h5
                          className="accordion-title accordion-title-sm collapsed"
                          data-toggle="collapse"
                          data-target="#faq-47-5"
                      >
                        {" "}
                        Why can’t I stake vested CHANGE?{" "}
                        <span className="accordion-icon accordion-icon-s2"></span>
                      </h5>
                      <div id="faq-47-5" className="collapse" data-parent="#faq-47">
                        <div className="accordion-content">
                          <p>
                            When you vest your CHANGE, you lock those tokens for a certain time period and reduce the circulating supply of CHANGE tokens.
                            This, however, does not stop you from buying at the IDO in order to stake CHANGE and enjoy the high APR,
                            which gives you a mix of the two strategies. CHANGE tokens will be released to ICO buyers on September 30 to those
                            that are not self-vesting. The ICO participants that are self-vesting will receive their tokens according to the selected period,
                            so If e.g. somebody is self-vesting for 6 months, they will receive their tokens on March 31, 2023.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item accordion-item-s2 bg-theme-alt">
                      <h5
                          class="accordion-title accordion-title-sm collapsed"
                          data-toggle="collapse"
                          data-target="#faq-47-6"
                      >
                        {" "}
                        When will CHANGE staking be available?{" "}
                        <span class="accordion-icon accordion-icon-s2"></span>
                      </h5>
                      <div id="faq-47-6" class="collapse" data-parent="#faq-47">
                        <div class="accordion-content">
                          <p>
                            The ChangeX app and CHANGE staking will be available in July for users who bought the token from the HYDRA DEX. Investors who bought during the ICO subscription phase will receive their tokens in September without self-vesting or according to the self-vesting period they opted for.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item accordion-item-s2 bg-theme-alt">
                      <h5
                          className="accordion-title accordion-title-sm collapsed"
                          data-toggle="collapse"
                          data-target="#faq-47-7"
                      >
                        {" "}
                        What happens with the deposit if I subscribe for an
                        amount that I can't cover?{" "}
                        <span className="accordion-icon accordion-icon-s2"></span>
                      </h5>
                      <div id="faq-47-7" className="collapse" data-parent="#faq-47">
                        <div className="accordion-content">
                          <p>
                            The 3% deposit has a symbolic size relative to the subscription. It aims to incentivize
                            participants to subscribe for realistic amount in order to have a fair and responsible
                            event. In case a participant enrolls for an amount that he/she isn't able to cover
                            afterward, the deposit will be deducted as a penalty fee. The user will still be able to
                            participate with a lower amount and he/she will still have access to the event.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="ico-questions-13">
                  <div class="accordion accordion-faq" id="faq-48">
                    <div class="accordion-item accordion-item-s2 bg-theme-alt">
                      <h5
                        class="accordion-title accordion-title-sm"
                        data-toggle="collapse"
                        data-target="#faq-48-1"
                      >
                        {" "}
                        Are there any country limitations for participating?{" "}
                        <span class="accordion-icon accordion-icon-s2"></span>
                      </h5>
                      <div
                        id="faq-48-1"
                        class="collapse show"
                        data-parent="#faq-48"
                      >
                        <div class="accordion-content">
                          <p>
                          Residents of the USA, Canada, Singapore, and China are not allowed to participate. Any KYC and/or applications from members of these countries will be automatically disqualified.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item accordion-item-s2 bg-theme-alt">
                      <h5
                        class="accordion-title accordion-title-sm collapsed"
                        data-toggle="collapse"
                        data-target="#faq-48-2"
                      >
                        {" "}
                        How does the CHANGE token work?{" "}
                        <span class="accordion-icon accordion-icon-s2"></span>
                      </h5>
                      <div id="faq-48-2" class="collapse" data-parent="#faq-48">
                        <div class="accordion-content">
                          <p>
                          The CHANGE token has a unique utility of facilitating the DeFi products in the ChangeX platform.
                            Leveraged staking pools, trading pairs, and all transactions in the app will interact with the CHANGE token.
                            A ~5% network fee generated by the staking pools will be airdropped to CHANGE token owners as rewards,
                            thus creating a bridge between the staked inflationary assets and the deflationary economy of CHANGE.
                            Additionally, the CHANGE token will act as the governance token for the ChangeX DAO,
                            providing voting rights to users, while 30% of all trading fees in the app will be used to burn the total supply and stimulate deflation.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item accordion-item-s2 bg-theme-alt">
                      <h5
                        class="accordion-title accordion-title-sm collapsed"
                        data-toggle="collapse"
                        data-target="#faq-48-3"
                      >
                        {" "}
                        Does the team behind the project have any track history?{" "}
                        <span class="accordion-icon accordion-icon-s2"></span>
                      </h5>
                      <div id="faq-48-3" class="collapse" data-parent="#faq-48">
                        <div class="accordion-content">
                          <p>
                          Six of the co-founders have extensive experience in the world of blockchain.
                            Together, they have 3 successful projects behind themselves with a combined market cap of 600M USDT – HYDRA,
                            LockTrip, and Bitcoin Gold, all having distinct models, unique attributes, and active communities.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item accordion-item-s2 bg-theme-alt">
                      <h5
                        class="accordion-title accordion-title-sm collapsed"
                        data-toggle="collapse"
                        data-target="#faq-48-4"
                      >
                        {" "}
                        What happens after the commitment phase ends?{" "}
                        <span class="accordion-icon accordion-icon-s2"></span>
                      </h5>
                      <div id="faq-48-4" class="collapse" data-parent="#faq-48">
                        <div class="accordion-content">
                          <p>
                            The commitment phase will end on June 30 at 00:00 EEST. Once that is over, CHANGE will make its appearance on the HYDRA DEX for a liquidity event, where investors can buy the token and stake it immediately once staking is online in the app. We will then work proactively to get CHANGE listed on more exchanges and launchpads.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="tokens-sales-13">
                  <div class="accordion accordion-faq" id="faq-49">
                    <div class="accordion-item accordion-item-s2 bg-theme-alt">
                      <h5
                        class="accordion-title accordion-title-sm"
                        data-toggle="collapse"
                        data-target="#faq-49-1"
                      >
                        {" "}
                        If I pay the deposit with one cryptocurrency, can I make the final settlement with a different one?{" "}
                        <span class="accordion-icon accordion-icon-s2"></span>
                      </h5>
                      <div
                        id="faq-49-1"
                        class="collapse show"
                        data-parent="#faq-49"
                      >
                        <div class="accordion-content">
                          <p>
                          Yes, we want to make the procedure as flexible as possible and will strive to support all flagman coins and stablecoins. You can enroll in Phase 1 with any of the supported coins, and afterwards commit the final amount in Phase 2 again with a different coin from the supported list.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item accordion-item-s2 bg-theme-alt">
                      <h5
                        class="accordion-title accordion-title-sm collapsed"
                        data-toggle="collapse"
                        data-target="#faq-49-2"
                      >
                        {" "}
                        Will there be any institutional investors?{" "}
                        <span class="accordion-icon accordion-icon-s2"></span>
                      </h5>
                      <div id="faq-49-2" class="collapse" data-parent="#faq-49">
                        <div class="accordion-content">
                          <p>
                          There will be an invite-only private placement intended for tier 1 institutional investors with an allocation of $0.8M. Only selected entities with a solid reputation will be invited for discussions. The price will be identical to that for all other participants.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item accordion-item-s2 bg-theme-alt">
                      <h5
                        class="accordion-title accordion-title-sm collapsed"
                        data-toggle="collapse"
                        data-target="#faq-49-3"
                      >
                        {" "}
                        Will there be a cap per participant?{" "}
                        <span class="accordion-icon accordion-icon-s2"></span>
                      </h5>
                      <div id="faq-49-3" class="collapse" data-parent="#faq-49">
                        <div class="accordion-content">
                          <p>
                            The default cap per participant is $100,000
                            equivalent.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item accordion-item-s2 bg-theme-alt">
                      <h5
                        class="accordion-title accordion-title-sm collapsed"
                        data-toggle="collapse"
                        data-target="#faq-49-4"
                      >
                        {" "}
                        The quota will be very limited, how can I buy more?{" "}
                        <span class="accordion-icon accordion-icon-s2"></span>
                      </h5>
                      <div id="faq-49-4" class="collapse" data-parent="#faq-49">
                        <div class="accordion-content">
                          <p>
                          Shortly after the finalization of the settlement phase, there will be a public DEX event with significant liquidity. It will allow anyone to top-up their desired quantity at aftermarket terms.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div
                class="nk-block-img mt-4 mt-lg-0 animated"
                data-animate="fadeInUp"
                data-delay=".6"
              >
                <StaticImage
                  src="../../static//assets/images/gfx/gfx-p.png"
                  alt="lungwort"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
