import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

export default function AdditionalUsp() {
  return (
    <section class="section bg-theme-alt ov-h pb-0 tc-light">
      <div class="background-shade">
        <div class="container">
          <div class="background-shade-left"></div>
          <div class="background-shade-right"></div>
        </div>
      </div>
      <div class="container">
        <div class="section-head section-head-s4 wide-auto-sm text-center">
          <h2
            class="title title-s2 title-s2-alt animated"
            data-animate="fadeInUp"
            data-delay=".1"
          >
            Additional Product Features
          </h2>
        </div>

        <div class="nk-block">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-9 pt-lg-5 mt-xl-4">
              <div class="gutter-vr-30px">
                <div
                  class="feature feature-inline feature-md-reverse animated"
                  data-animate="fadeInUp"
                  data-delay=".2"
                >
                  <div class="feature-icon feature-icon-s7">
                    <em class="icon ti ti-view-list"></em>
                  </div>
                  <div class="feature-text">
                    <h5 class="title title-sm">Flexible Staking Pools </h5>
                    <p>
                      One-click access to staking HYDRA and other PoS assets
                    </p>
                  </div>
                </div>
                <div
                  class="feature feature-inline feature-md-reverse animated"
                  data-animate="fadeInUp"
                  data-delay=".3"
                >
                  <div class="feature-icon feature-icon-s7">
                    <em class="icon ti ti-wallet"></em>
                  </div>
                  <div class="feature-text">
                    <h5 class="title title-sm">DeFi Wallet</h5>
                    <p>
                    You are in control of your private keys and seed phrase
                    </p>
                  </div>
                </div>
                <div
                  class="feature feature-inline feature-md-reverse animated"
                  data-animate="fadeInUp"
                  data-delay=".3"
                >
                  <div class="feature-icon feature-icon-s7">
                    <em class="icon ti ti-exchange-vertical"></em>
                  </div>
                  <div class="feature-text">
                    <h5 class="title title-sm">Crypto-to-Crypto Trading</h5>
                    <p>
                    On all pairs in the app
                    </p>
                  </div>
                </div>
                <div
                  class="feature feature-inline feature-md-reverse animated"
                  data-animate="fadeInUp"
                  data-delay=".3"
                >
                  <div class="feature-icon feature-icon-s7">
                    <em class="icon ti ti-wallet"></em>
                  </div>
                  <div class="feature-text">
                    <h5 class="title title-sm">NFT Wallet</h5>
                    <p>
                    Securely hold and transfer your purchased NFTs with the click of a button
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-9 pt-lg-5 mt-xl-4 order-lg-last">
              <div class="gutter-vr-30px">
                <div
                  class="feature feature-inline animated"
                  data-animate="fadeInUp"
                  data-delay=".4"
                >
                  <div class="feature-icon feature-icon-s7">
                    <em class="icon ti ti-world"></em>
                  </div>
                  <div class="feature-text">
                    <h5 class="title title-sm">
                    Stablecoin Lending Market
                    </h5>
                    <p>Lend for an attractive APR and power the Leveraged Staking economy</p>
                  </div>
                </div>
                <div
                  class="feature feature-inline animated"
                  data-animate="fadeInUp"
                  data-delay=".5"
                >
                  <div class="feature-icon feature-icon-s7">
                    <em class="icon ti ti-money"></em>
                  </div>
                  <div class="feature-text">
                    <h5 class="title title-sm">"Earn" Product</h5>
                    <p>Enabels fixed APR for stablecoins</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-9 mt-4 mt-xl-0 order-2 align-self-end">
              <div
                class="nk-block-image text-center px-xl-4 px-lg-0 px-5 animated"
                data-animate="fadeInUp"
                data-delay=".6"
              >
                <StaticImage
                  src="../../static/assets/images/app-screens/additional-usps.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
