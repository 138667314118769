import * as React from "react";

export default function RoadMap() {
  const roadMapList = [
    [
      {
        done: true,
        title: "Q4 2021",
        subtitle: "R&D",
        content: [
          "Research for Leveraged Staking & DeFi Products",
          "MVP - Non-custodial wallet with a few supported cryptocurrencies, incl. HYDRA",
          "Prototyping Composable Earn Products",
        ],
      },
      {
        done: true,
        title: "Q1 2022",
        subtitle: "Legal Setup",
        content: [
          "Establish Legal entity",
          "Secure Seed Investment from HYDRA & LockTrip",
          "Team Structuring",
          "HYDRA SDKs for Swift, Kotlin and Go",
        ],
      },
      {
        done: true,
        title: "Feb 28 2022",
        subtitle: "Subscription Start With 3% Deposit",
        content: [],
      },
      {
        done: true,
        title: "March 28 2022",
        subtitle: "100% Subscription reached",
        content: [],
      },
    ],
    [
      {
        done: true,
        title: "30 June 2022",
        subtitle: "Subscription Enrollment End",
        content: [,],
      },
      {
        done: true,
        title: "1 June 2022",
        subtitle: "Subscription Quota Calculation & Settlement Start",
        content: [],
      },
      {
        done: true,
        title: "June 2022",
        subtitle: "Release of ChangeX Wallet",
        content: [
          "Official release of ChangeX v1 wallet",
        ],
      },
      {
        done: true,
        title: "May 31 2022",
        subtitle: "Subscription enrollment ends",
        content: [
        ],
      },
      {
        done: true,
        title: "May 2022",
        subtitle: "Open Beta",
        content: [
          "Release of Open Beta of ChangeX wallet",
        ],
      },
      {
        done: true,
        title: "Q2 2022",
        subtitle: "Stage 1",
        content: [
          "Start Process for Obtaining Crypto License in Lithuania",
          "Sign agreement for AML Services with Tier 1 Partner",
          "Conduct Private and Public Sales"
        ],
      },
    ],
    [
      {
        title: "July 2022",
        subtitle: "Crowdfunding Phase 3",
        content: [
          "HydraDEX Launch",
          "Staking on CHANGE Begins",
          "Launchpad Events",
        ],
      },

      {
        title: "Q3 2022",
        subtitle: "Delegated Staking on HYDRA",
        content: ["Also In-wallet DeFi swaps", 
        "Support for 10 coins and tokens in wallet"
      ],
      },
      {
        title: "Q4 2022",
        subtitle: "ChangeX Crypto Visa Card",
        content: ["Support for additional chains and tokens"],
      },
      {
        title: "Q4 2022 and Beyond",
        subtitle: "Fiat Trading",
        content: ["Third-party fiat on-ramp with direct fiat purchases"],
      },
    ],
    [
      {
        title: "Q1-Q2 2023 and Beyond",
        subtitle: "Levaraged staking and Earn",
        content: ["Leveraged Staking Functionality", 
        "HYDRA, CAKE, BNT and other high APR assets",
        "Open Lending Market"],
      },
      {
        title: "Q3 2023 and Beyond",
        subtitle: "Hybrid Earn Product with high APR for Stablecoins and Fiat",
        content: [],
      },
      {
        title: "2023",
        subtitle: "SEPA IBAN Functionality",
        content: [
          "Electronic Money Institution (EMI) Licensing or Agency",
        ],
      },
    ],
  ];

  return (
    <section className="section bg-theme-alt tc-light" id="roadmap">
      <div className="container">
        <div className="section-head section-head-s9 wide-md">
          <h6
            className="title title-xs title-s1 tc-primary animated"
            data-animate="fadeInUp"
            data-delay=".1"
          >
            Timeline
          </h6>
          <h2
            className="title title-semibold animated"
            data-animate="fadeInUp"
            data-delay=".2"
          >
            Road Map
          </h2>
          <div className="wide-sm">
            <p className="animated" data-animate="fadeInUp" data-delay=".3">
              This is a highly tentative roadmap that shows how the ChangeX
              vision will unfold
            </p>
          </div>
        </div>

        <div className="nk-block nk-block-left">
          <div
            className="roadmap-all mgb-m50 animated"
            data-animate="fadeInUp"
            data-delay=".4"
          >
            {roadMapList.map((roadMap) => (
              <div className="roadmap-wrap  roadmap-wrap-s1 roadmap-wrap-s1-alt mb-0 ml-0">
                <div className="row no-gutters">
                  {roadMap.map(({ title, subtitle, content, done }) => (
                    <div className="col-lg">
                      <div
                        className={`roadmap roadmap-s1 roadmap-s1-alt text-lg-center ${
                          done ? "roadmap-done" : ""
                        }`}
                      >
                        <div className="roadmap-step roadmap-step-s1 rounded">
                          <div className="roadmap-head roadmap-head-s1">
                            <span className="roadmap-time roadmap-time-s1">
                              {title}
                            </span>
                            <span className="roadmap-title roadmap-title-s1">
                              {subtitle}
                            </span>
                          </div>
                          <ul className="roadmap-step-list roadmap-step-list-s1 rounded p-5">
                            {content.map((item) => (
                              <li>{item}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
