import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

export default function Advisors() {

  const advisors = [
    {
      name: "Dimiter Gurdjilov",
      title: "Advisory board member",
      description:
        "Senior M&A and investment professional with 25+ years of experience with Merrill Lynch Asset Management, JPMorgan, George Soros’ Bedminster Capital and NBGI Private Equity in New York, Sydney and London.  As an M&A Banker at JPMorgan, he executed transactions with value in excess of $80bn. As an investment principal at Bedminster and NBGI PE, led investments in numerous transactions with cumulative IRR in excess of 75% and 50%, respectively. Dimiter holds an MBA in International Finance from University of Miami and a BA in Business Administration & Economics from the American University in Bulgaria.",
      linkedIn: "https://www.linkedin.com/in/dimitergurdjilov/",
      image: (
        <StaticImage
          src={"../../static/assets/images/team/dimitar-gurdjilov.jpg"}
          alt={"Dimiter Gurdjilov"}
          className="round-full"
        />
      ),
    },
    {
      name: "Stefan Ivanov",
      title: "Advisory board member",
      description:
        "Senior Banker with 25+ years of experience with Citibank, Banque Paribas and Challenger Capital Management.  Served as CEO of Citibank in Bulgaria bringing the bank to #1 position. Held senior roles with Citi in London, Sao Paulo, Seoul and Tunisia.  Traded derivatives at Banque Paribas in New York and on the floor of the American Stock Exchange in New York. Chairman of the Audit Committee of UBB - KBC Group in Bulgaria. Stefan is an alum of Harvard Business School and holds an MBA in Financial Engineering from Cornell University and a BA in Business Administration from the American University in Bulgaria.  What is more, in 2020, Stefan and his son crossed the Atlantic on a self-made boat, entirely on their own, with the mission to raise awareness for an organ transplant program!",
      linkedIn: "https://www.linkedin.com/in/stefan-ivanov-301a138/",
      image: (
        <StaticImage
          src={"../../static/assets/images/team/stefan-ivanov.jpg"}
          alt={"Stefan Ivanov"}
          className="round-full"
        />
      ),
    },
    {
      name: "Neil Cunha-Gomes",
      title: "Advisory board member",
      description:
          "Over the past 5 years, Neil has led a number of high-profile investments globally at the SoftBank Vision Fund, which has over $100 billion AUM, where he focuses on Growth Equity and Crypto investing. His crypto investments include Consensys (one of the most reputable brands in the industry, behind flagship projects such as MetaMask) and Elliptic (one of the most trusted crypto compliance solutions providers). His 10+ years of investing experience is a perfect addition to the ChangeX advisory board.",
      linkedIn: "https://www.linkedin.com/in/neil-cunha-gomes-89998a14/",
      image: (
          <StaticImage
              src={"../../static/assets/images/team/neil-cunha-gomes.jpg"}
              alt={"Neil Cunha-Gomes"}
              className="round-full"
          />
      ),
    },
  ]



  return (
    <section className="section bg-theme-dark-alt tc-light" id="team">
        <div className="background-shape bs-right"></div>
        <div className="container">
        <div className="section-head section-head-s9 wide-md">
          <h6
            className="title title-xs title-s1 tc-primary animated"
            data-animate="fadeInUp"
            data-delay=".1"
          >
            MEET THE TEAM
          </h6>
          <h2
            className="title title-semibold animated"
            data-animate="fadeInUp"
            data-delay=".2"
          >
            Advisory board
          </h2>
          <div className="wide-sm">
            <p className="animated" data-animate="fadeInUp" data-delay=".3">
            With more than 50 years of combined experience in M&amp;A, investment banking, and management, our advisors are focused on helping us grow and expand by raising capital and guiding us with our strategic decisions.
            </p>
          </div>
        </div>

        <div className="nk-block nk-block-left nk-block-team-list team-list">
          <div className="row justify-content-start gutter-vr-30px ">
            {advisors.map(
              ({ name, title, description, image, linkedIn }, index) => (
                <div className="col-lg-4 col-sm-6 gap-5">
                  <div
                    className="team team-s4 round bg-theme-alt h-100 ml-0 animated"
                    data-animate="fadeInUp"
                    data-delay={index / 10 + 0.2}
                  >
                    <div className="team-photo team-photo-s1 round-full">
                      {image}
                    </div>
                    <h5 className="team-name">{name}</h5>
                    <span className="team-position tc-primary">{title}</span>
                    <div className="team-desc">
                      <p>{description}</p>
                    </div>
                    <ul className="team-social team-social-s2">
                      <li>
                        <a href={linkedIn}>
                          <em className="fab fa-linkedin-in"></em>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              )
            )}
            </div>
          </div>
        </div>
    </section>
  );
}
