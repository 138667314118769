import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

export default function TheToken() {
  return (
    <section className="section bg-theme tc-light" id="token">
      <div className="container">
        <div className="nk-block nk-block-features-s2">
          <div className="row align-items-center gutter-vr-30px">
            <div className="col-md-6">
              <div
                className="gfx animated"
                data-animate="fadeInUp"
                data-delay=".1"
              >
                <StaticImage
                  src="../../static/assets/images/lungwort/gfx-l.png"
                  alt="gfx"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="nk-block-text">
                <h6
                  className="title title-xs title-s1 tc-primary animated"
                  data-animate="fadeInUp"
                  data-delay=".2"
                >
                  ChangeX is powered by
                </h6>
                <h2
                  className="title title-semibold animated"
                  data-animate="fadeInUp"
                  data-delay=".3"
                >
                  The CHANGE token
                </h2>
                <p
                  className="lead animated"
                  data-animate="fadeInUp"
                  data-delay=".4"
                >
                  <strong>CHANGE</strong> is the governance token of the ChangeX
                  app. It is a deflationary HRC20 token (HydraChain), which
                  offers high APR to holders by acting as a bridge to the staked
                  inflationary assets in the app, where rewards will be paid out
                  in CHANGE.
                </p>
                <p
                  className="lead animated"
                  data-animate="fadeInUp"
                  data-delay=".5"
                >
                  30% of all crypto-crypto and fiat-crypto trading fees in the
                  app will be used to power a <strong>burn mechanism</strong> to
                  reduce total supply, thus driving the price upwards.
                </p>
                <p
                  className="animated lead"
                  data-animate="fadeInUp"
                  data-delay=".6"
                >
                  <strong>CHANGE</strong> will also provide governance rights to holders,
                  allowing them to vote on new additions to the ecosystem.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
