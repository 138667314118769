import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

export default function DownloadDocuments() {
  return (
    <section class="section bg-theme tc-light">
      <div class="container">
        <div class="section-head section-head-s9 wide-md">
          <h6
            class="title title-xs title-s1 tc-primary animated"
            data-animate="fadeInUp"
            data-delay=".1"
          >
            Download Documents
          </h6>
          <h2
            class="title title-semibold animated"
            data-animate="fadeInUp"
            data-delay=".2"
          >
            Read Our Documents
          </h2>
          <div class="wide-sm">
            <p class=" animated" data-animate="fadeInUp" data-delay=".3">
              Please download our Whitepaper and make sure to follow closely as
              we release more product related materials.
            </p>
          </div>
        </div>

        <div class="nk-block nk-block-features">
          <div class="row gutter-vr-30px">
            <div class="col-xl-4 col-sm-6">
              <div
                class="doc doc-s2 bg-theme-dark-alt animated"
                data-animate="fadeInUp"
                data-delay=".4"
              >
                <div class="doc-photo no-hover">
                  <StaticImage
                    src="../../static/assets/images/docs/alt-sm-a.png"
                    alt="doc"
                  />
                </div>
                <div class="doc-text">
                  <h6 class="doc-title title-xs-alt">Whitepaper</h6>
                  <ul class="btn-grp gutter-10px">
                    <li>
                      <a
                        class="btn btn-outline btn-xxs btn-auto btn-light"
                        href="https://firebasestorage.googleapis.com/v0/b/changex-io.appspot.com/o/changex-whitepaper-v4.pdf?alt=media&token=85d7033d-ce62-4fd2-aefd-b50f2a758530"
                      >
                        PDF
                      </a>
                    </li>
                    <li>
                      <a
                        class="btn btn-outline btn-xxs btn-auto btn-light"
                        href="https://firebasestorage.googleapis.com/v0/b/changex-io.appspot.com/o/changex-whitepaper-v4.pdf?alt=media&token=85d7033d-ce62-4fd2-aefd-b50f2a758530"
                      >
                        WEB
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-sm-6">
              <div
                class="doc doc-s2 bg-theme-dark-alt animated"
                data-animate="fadeInUp"
                data-delay=".5"
              >
                <div class="doc-photo no-hover">
                  <StaticImage
                    src="../../static/assets/images/docs/alt-sm-b.png"
                    alt="doc"
                  />
                </div>
                <div class="doc-text">
                  <h6 class="doc-title title-xs-alt">Announcement</h6>
                  <ul class="btn-grp gutter-10px">
                    <li>
                      <a
                        class="btn btn-outline btn-xxs btn-auto btn-light"
                        href="https://medium.com/locktrip/unveiling-project-x-leveraged-staking-defi-fiat-trading-visa-card-for-hydra-ecosystem-b9bfdf872de5"
                      >
                        WEB
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-sm-6">
              <div
                class="doc doc-s2 bg-theme-dark-alt animated"
                data-animate="fadeInUp"
                data-delay=".6"
              >
                <div class="doc-photo no-hover">
                  <StaticImage
                    src="../../static/assets/images/docs/alt-sm-d.png"
                    alt="doc"
                  />
                </div>
                <div class="doc-text">
                  <h6 class="doc-title title-xs-alt">General Terms</h6>
                  <ul class="btn-grp gutter-10px">
                    <li>
                      <a
                        class="btn btn-outline btn-xxs btn-auto btn-light"
                        href="https://token.changex.io/terms-and-condition"
                      >
                        ENG
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
